<template>
  <div class="main-box">
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini">
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          label="类型">
      </el-table-column>
      <el-table-column
          prop="update_time"
          label="编辑时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="编辑"
        :visible.sync="showModel"
        :destroy-on-close="true"
        width="900px">
      <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModel = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveTxt" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import WangEdit from "@/components/WangEdit";
export default {
  components: {
    WangEdit
  },
  data() {
    return {
      showModel: false,
      info: {
        id: '',
        title: '',
        content: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    setText(txt) {
      this.info.content = txt
    },
    saveTxt() {
      this.showLoading()
      var param = {
        id: this.info.id,
        title: this.info.title,
        content: this.info.content
      }
      this.$api.merchant.pageEdit(param, res => {
        this.hideLoading()
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.showModel = false
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
      }
      this.$api.merchant.pageIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    editGroup(item) {
      this.info.id = item.id
      this.info.title = item.title
      this.info.content = item.content
      this.showModel = true
    }
  }
};
</script>
<style scoped>
</style>
